.bg-dark-gray {
  background-color: #f1f1f1;
}
.bg-primary-300 {
  background-color: #007ee0 !important;
}
.bg-primary-400 {
  background-color: #007ddf !important;
  &:hover {
    background-color: #1c84e6 !important;
  }
}
.statusBg-primary-400 {
  background-color: #007ddf !important;
}
.bg-primary-500 {
  background-color: #1990ff !important;
}
.bg-green-500 {
  background-color: #75ce82 !important;
}

.text_primary_300 {
  color: #4c9deb !important;
}
.text-primary-400 {
  color: #007ddf !important;
}
.text-green-400 {
  color: #75ce82;
}
.text-greenBlue-gradient {
  background: -webkit-linear-gradient(#007ddf 50%, #9fea61);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text_white {
  color: #fff !important;
}
