.contactUs-container {
  @include breakpoint(tablet) {
    flex-direction: column !important;
  }
  .contactUs-left-container {
    border-left: 5px solid #007ddf;
    height: fit-content;
  }
  .contactUs-right-container {
    border-right: 5px solid #007ddf;
    height: fit-content;
  }
  .form-container {
    input.ant-input.w-full.rounded-lg {
      padding: 10px !important;
      border-radius: 5px;
    }
    button {
      border-radius: 10px!important;
    }
  }
}

