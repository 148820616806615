// width
.w-30 {
  width: 30px;
}
.w-20p {
  width: 20%;
}
.w-22p {
  width: 22% !important;
}
.w-25p {
  width: 25%;
}
.w-35p {
  width: 35%;
}
.w-33p {
  width: 33%;
}
.w-40p {
  width: 40%;
}
.w-60p {
  width: 60%;
}
.w-65p {
  width: 65%;
}
.w-67p {
  width: 67%;
}
.w-64p {
  width: 66.666667%;
  @media (max-width: 1023px) and (min-width: 320px) {
    width: 100%;
  }
}
.w-75p {
  width: 75%;
}
.w-90p {
  width: 90%;
}
// height
.h-18 {
  height: 4.5rem;
}
.h-30 {
  height: 30px;
}
.h-400 {
  height: 400px !important;
}
.h-fit-content {
  height: fit-content !important;
}
// top
.top-8 {
  top: 2rem !important;
}
.top-48 {
  top: 12rem !important;
}
// margin
.mt-1 {
  margin-top: 1rem !important;
}
.mt-0 {
  margin-top: 0rem !important;
}
// border-radius
.border-t-l-10 {
  border-top-left-radius: 10px;
}
.border-t-r-10 {
  border-top-right-radius: 10px;
}
.border-b-l-10 {
  border-bottom-left-radius: 10px;
}
.border-b-r-10 {
  border-bottom-right-radius: 10px;
}

// outline button
.active-border {
  border: 1px solid #007ddf !important;
}
.outline-primary-button {
  background: white !important;
  border: 1px solid #007ddf !important;
  color: #007ddf !important;
  border-radius: 5px !important;
  &:hover {
    background: #3d81f6 !important;
    border: 1px solid #3d81f6 !important;
    color: #fff !important;
    border-radius: 5px !important;
  }
}
.outline-secondary-button {
  border-radius: 5px !important;
  &:hover {
    border-radius: 5px !important;
  }
}
// padding
.p-10 {
  padding: 10px !important;
}

// shadow
.shadow_md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow_lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.hover_shadow_lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow_xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

// border
.border-tl-radius {
  border-top-left-radius: 5px;
}
.border-tr-radius {
  border-top-right-radius: 5px;
}
.border-bl-radius {
  border-bottom-left-radius: 5px;
}
.border-br-radius {
  border-bottom-right-radius: 5px;
}

// pre wrap
.whitespace_prewrap {
  white-space: pre-wrap !important;
}

// z-index
.z-60 {
  z-index: 60 !important;
}

//
.fs_22 {
  font-size: 22px !important;
}
.fs_16 {
  font-size: 16px !important;
}
.fs_15 {
  font-size: 15px !important;
}

// common class for renovation images in model
.w_80p {
  width: 80%;
}
.w_20p {
  width: 20%;
}
// top
.top-80 {
  top: 80px;
}
