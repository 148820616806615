.profile_page_container {
  .profile_sections_container {
    display: flex;
    justify-content: center;
    @media (max-width: 1023px) and (min-width: 320px) {
      display: initial !important;
      justify-content: initial !important;
    }
  }
  .section_one_container {
    h2 {
      font-size: 22px;
    }
    span {
      font-size: 16px !important;
      font-weight: 300px !important;
    }
  }
  .profile-section-container {
    margin: 15px 0px !important;
    @media (max-width: 1023px) and (min-width: 320px) {
      overflow: scroll !important;
    }
    .table_body {
      @media (max-width: 1023px) and (min-width: 320px) {
        overflow-x: scroll !important;
        width: 700px !important;
      }
    }
    .button-container {
      button {
        display: flex !important;
        align-items: center !important;
        padding: 20px !important;
        border-radius: 6px !important;
        p {
          margin: 0px;
        }
      }
    }
    .primaryButton-container {
      button {
        display: flex !important;
        align-items: center !important;
        padding: 10px 20px !important;
        border-radius: 6px !important;
        background: #007ddf !important;
      }
    }
  }
}
.ant_buttons_container {
  .ant-btn {
    height: 46px !important;
  }
  .icons_container {
    .ant-btn {
      border: 1px solid #e9e9e9 !important;
      color: #000 !important;
      &:hover {
        background: #e9e9e9 !important;
      }
    }
  }
}
