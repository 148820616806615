// .select_option_container {
.ant-select-item-option-content {
  overflow: auto !important;
  white-space: initial !important;
  text-overflow: initial !important;
}
// }
.result_page_carousel {
  .glide__slide {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 23% !important;
  }
}
.result_common_model_container {
  .tool_tip_button {
    outline: none !important;
    border: navajowhite !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .ant-slider-handle {
    width: 18px !important;
    height: 18px !important;
    margin-top: -7px !important;
    border: solid 2px #007ddf !important;
  }
  .ant-btn-primary {
    border: solid 2px #007ddf !important;
    background-color: #007ddf !important;
    &:hover {
      background-color: #0470c2 !important;
    }
  }
  .ant-menu-item-selected {
    color: #007ddf !important;
    span {
      color: #007ddf !important;
    }
  }
  .ant-slider-track {
    background-color: #007ddf !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #007ddf !important;
  }
  .ant-radio-inner::after {
    background-color: #007ddf !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #007ddf;
    border-color: #007ddf;
  }
}
.result_page_main_container {
  // to add on all slider
  .ant-slider-handle {
    width: 18px !important;
    height: 18px !important;
    margin-top: -7px !important;
    border: solid 2px #007ddf !important;
  }
  .ant-btn-primary {
    border: solid 2px #007ddf !important;
    background-color: #007ddf !important;
    &:hover {
      background-color: #0470c2 !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #007ddf;
    border-color: #007ddf;
  }
  .ant-slider-track {
    background-color: #007ddf !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #007ddf !important;
  }
  .ant-radio-inner::after {
    background-color: #007ddf !important;
  }
  //
  .resultDescription_container {
    .cardCateory6_container {
      .hover_box2 {
        min-height: 235px;
        overflow-y: auto;
        padding: 0px 0px 20px 0px;
        z-index: 11;
      }
    }
    .heading_container {
      h2 {
        font-size: 22px;
      }
      span {
        font-size: 18px;
      }
    }
    .slider_li {
      height: inherit !important;
      margin-right: 0px !important;
      margin-left: 0px !important;
      width: 180px !important;
    }
  }
  .house_container_result {
    .house_map {
      width: 50%;
      @media (max-width: 1023px) and (min-width: 320px) {
        width: 95%;
      }
    }
    #viewDiv {
      padding: 0;
      margin: 0;
      min-height: 520px;
      width: 100%;
      .esri-view .esri-view-surface--inset-outline {
        border-top-left-radius: 15px !important;
      }
    }
  }
  .section-one-container {
    .section_width {
      width: 48%;
      @media (max-width: 1023px) and (min-width: 320px) {
        width: 100%;
      }
    }
    .diagnosis-container {
      .daignosis-bottom-content {
        background-color: #ffc197 !important;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
  .recomendation_container {
    .main_title {
      h2 {
        font-size: 22px;
      }
    }
    .disabled {
      .ant-switch-handle {
        margin: 0px 0px 0px 10px !important;
      }
      .ant-switch-checked .ant-switch-handle {
        margin: 0px 0px 0px -11px !important;
      }
    }
    .savings_complexity_comfort {
      .greenDot {
        background-color: #75ce82;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: 0px 2px;
      }
      .grayDot {
        background-color: #e3e7eb;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: 0px 2px;
      }
    }
    .card_container {
      padding: 20px 8px 20px 6px !important;
      .selected-img {
        position: absolute;
        z-index: 1;
        img {
          height: 115px;
          margin: -24px 0px 0px -8px;
        }
      }
    }
    .br_r {
      height: auto;
      width: 1px;
      border-right: 1px solid #e3e7eb;
      @media (max-width: 1023px) and (min-width: 800px) {
        display: none;
      }
    }
  }
  .sectionLoad_container {
    .main_title {
      h2 {
        font-size: 22px;
      }
    }
  }
  .section_fixedBar {
    .nc-Button {
      background-color: #007ddf !important;
    }
    .epc-label-status {
      padding: 5px 6px 5px 0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0px;
      clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
      width: 15%;
      text-align: center;
      color: #ffff;
      font-size: 20px;
    }
    .status-A {
      background: #50a546;
    }
    .status-B {
      background: #e3e996;
    }
    .status-C {
      background: #c8d32c;
    }
    .status-D {
      background: #fdb847;
    }
    .status-E {
      background: #f57e30;
    }
    .status-F {
      background: #e33c3d;
    }
  }
  .packs_container {
    .secondary_button {
      border: 1px solid #e3e7eb !important;
      background-color: #e3e7eb !important;
      border-radius: 5px !important;
      &:hover {
        background-color: #007ddf !important;
        color: #fff !important;
      }
    }
    .primary_button {
      border: 1px solid #007ddf !important;
      background-color: #007ddf !important;
      border-radius: 5px !important;
      color: #fff;
      &:hover {
        background-color: #0768b3 !important;
        color: #fff !important;
      }
    }
    .ant-btn-primary {
      border-radius: 5px !important;
    }
    .hand_image img {
      height: 120px;
      margin: 25px 0px 0px 50px;
    }
  }
  .result-header-container {
    .curve-line {
      clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 74% 100%, 100% 50%, 74% 0);
      background-color: gray;
    }
  }
}

.disabled_checkbox_container {
  .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
  }
}

// responsive
@media (max-width: 1023px) and (min-width: 320px) {
  .diagnosis-container {
    margin-top: 10px !important;
  }
  .responsive_text {
    font-size: 13px !important;
  }
}

.result_page_sections_container {
  display: flex;
  .fixed_sidebar_container {
    display: none;
    @media (max-width: 1300px) and (min-width: 320px) {
      margin-top: 10px;
      display: block !important;
    }
  }
  .left_side {
    width: 68%;
    @media (max-width: 1300px) and (min-width: 320px) {
      width: 100%;
    }
  }
  @media (max-width: 1300px) and (min-width: 1024px) {
    flex-direction: column;
  }
  .right_side_container {
    @media (max-width: 1300px) and (min-width: 320px) {
      // margin-top: 10px;
      display: none !important;
    }
  }
}
