.ttnc-autocomplete-search {
  .ant-select.ant-select-auto-complete.ant-select-single.ant-select-customize-input.ant-select-show-search {
    // width: 550px!important;
    @media (max-width: 1023px) and (min-width: 320px) {
      width: 100% !important;
    }
  }

  .ant-input-lg {
    padding: 15px 11px !important;
  }
  .ant-input-search-large .ant-input-search-button {
    height: 45px !important;
  }
  input {
    border-radius: 15px !important;
  }
  .ant-btn > span {
    display: inline-block;
    display: flex;
    justify-content: center;
  }
  button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
    position: absolute;
    top: 0px;
    right: 8px;
    bottom: 0px;
    margin: auto;
    // margin: -22.5px -210px;
    border-radius: 10px !important;
    z-index: 1;
    width: auto;
    font-size: 16px;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-right-width: 0px !important;
  }
  .ant-input-group-addon {
    background-color: none !important ;
    svg {
      font-size: 17px !important;
      margin: -4px 0px 0px 0px !important;
    }
  }
  .ant-input-group > .ant-input {
    display: initial !important;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 0px !important ;
    border: none !important ;
    background: no-repeat !important ;
    display: none !important ;
  }
  .autoComplete_search_button {
    position: absolute;
    margin: 6px 0px 0px -150px;
    background: #007ddf;
    color: white;
    border-radius: 10px;
    padding: 13px 12px;
    z-index: 11;
    width: 145px;
    &:hover {
      background: #0273ca;
    }
  }
}
.list-container {
  .rc-virtual-list-holder {
    max-height: 155px !important;
  }
}

.responsive_autoSearch {
  width: 100% !important;
}
