@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./mixins";
@import "./_tw_safe_classes";
@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
// @import "./_dates";
//
@import "./header";
@import "./components_sidenav";
@import "./profile";
@import "./common_custom_styles";
@import "./contact_us";
@import "./result_page";
@import "./modals";
@import "./renovate";
@import "./custom_colors";
@import "./homepage";
@import "./colors";
@import "./comming_soon";
@import "./auto_complete_search";
@import "./ui_improvements";
@import "./benefits";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

body {
  zoom: 80%;
  // min-height: 100vh;
}
.ant-slider {
  zoom: 1.25;
}
#hubspot-messages-iframe-container {
  zoom: 1.25
  // width: 30% !important;
  // height: 87% !important;
  // top: 135px;
}
#my-hubspot-form {
  zoom: 1.3 !important;
}
// common css for all inputs to remove arrows from input type number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}

.overflow_y_scroll {
  overflow-y: scroll !important;
}

// cookie modal class
.cookie_container {
  width: 25%;
  @media (max-width: 1023px) and (min-width: 320px) {
    width: 60%;
  }
}
.result_page_carousel {
  ul.glide__slides.flex.justify-between {
    width: 760px !important;
  }
}
