.nc-MainNav1 {
	&.notOnTop {
		@apply bg-white dark:bg-neutral-900 backdrop-blur-2xl bg-opacity-70 dark:bg-opacity-60 shadow-sm dark:border-b dark:border-neutral-700;
	}
	ul {
		margin-bottom: 0px !important;
	}
}


