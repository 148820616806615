.section-Grid-category-see-more {
    position: absolute !important;
    bottom: 0px !important;
    right: 15px !important;
}

.landing-page-check-mark {
    width: 20px !important;
    height: 20px !important;
}

.check-mark-text-vertical-centered {
    position: relative;
    bottom: 4px;
}

.landing-page-check-mark-div {
    display: flex;
    align-items: flex-start;
}

.landing-grade-image-vertical-center {
    justify-content: center;
    display: flex;
    flex-direction: column;
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
}

// .container {
//     width: 100%;
//     margin-right: auto;
//     margin-left: auto;
//     padding-right: 1rem;
//     padding-left: 0.5rem !important;
// }

.benefits-search-box-div {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}

.benefits-search-box-image {
    width: 85%;
    height: 10rem;
    padding-bottom: 13%;
    position: relative;
    top: 20px;
}

.benefits-checkmark {
    width: 30px !important;
    height: 30px !important;
}

.benefits-checkmark-title {
    font-size: 22px !important;
    font-weight: bold !important;
}

.side-nav-text-center {
    align-items: center;
    display: flex;
    // text-align: center;
    height: 100%;
    font-weight: bold;
}

.landing-notification-title {
   width:200px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
}

.landing-notification-content {
    width:100% !important;
    text-align: left;
    @extend .landing-notification-title;
 }

.testimonial-centered {
    display: flex;
    justify-content: center;
}

.no-display {
    display: none !important;
}

.carousel-status {
    @extend .no-display;
}

.right {
    float: right !important;
}

.wrap-text {
    white-space: pre-wrap !important;
}

.card-category {
    pointer-events: none;
}

.banner-heading {
    font-size: 40px;
    font-weight: 600;
}

.ant-collapse-content {
    border-top:1px solid white !important;
}


.header-logo-dutch {
    // height: 4rem;
    width: 12rem;
}

.landing-page-section1-title {
    width: 95% !important;
}

.benefit-title-vertically-center {
   height: 100% !important;
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
}


p {
    margin-bottom: 0px !important;
}

.landing-benefit-text {
    font-size: 25px !important;
}

