.text-blue-500 {
  color: #007ddf!important;
}

.text-green-400 {
  color: #75cf83;
}

.text-gray-400 {
  color: #a1a7ab;
}

.background_rgba {
  background: rgba(0, 0, 0, 0.3) !important;
}
