.support-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
  
.support-table td, .support-table th {
    border: none;
    padding: 8px;
    text-align: center;
}

.support-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #e2e7eb;
    color: black;
}

.support-table th:first-child{
    border-radius:10px 0 0 10px;
}
  
.support-table th:last-child{
    border-radius:0 10px 10px 0;
}

.support-table tr:nth-child(1) th:nth-child(1) {
    background-color: white !important;
}
.support-table tr:nth-child(1) th:nth-child(2) {
    background-color: white !important;
}
.support-table tr:nth-child(1) th:nth-child(3) {
    background-color: white !important;
}
.support-table tr:nth-child(1) th:nth-child(4) {
    background-color: white !important;
}

.support-table tr:nth-child(1) th:nth-child(5) {
    border-left: 10px solid white !important;
}

.support-table tr:nth-child(2) th:nth-child(5) {
    border-left: 10px solid white !important;
}

.support-table tr:nth-child(1) th:nth-child(6) {
    border-bottom-right-radius: 10px;
}

.support-table tr:nth-child(2) th:nth-child(8) {
    border-top-right-radius: 0px;
}

.support-table-th1 {
    background-color:#c4d500 !important ;
    color: white !important;
}

.support-table-th2 {
    background-color:#50aa37 !important;
    @extend .support-table-th1;
}

.support-table-th3 {
    background-color:#f1f5c7 !important;
}

.support-table-th4 {
    background-color:#cfead0 !important;

}

.curved-border {
    position:'relative'!important;
    bottom:10.5 !important;
}

.snake-image-overlap-hide {
    z-index: 9 !important;
    background-color: white;
    height: 100px;
}

.snake-image-overlap-text-hide {
    z-index: 9 !important;
    background-color: white;
}


