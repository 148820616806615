.calendar_container {
  .react-calendar {
    border: none !important;
    width: 100% !important;
  }
}
.refinement_house_modal_one_container {
  .energyConsumer_container {
    display: flex !important;
    flex-direction: column;
  }
}
.ant-spin {
  color: #007ddf !important;
}
.login_model_container {
  .button_container button {
    border: solid 2px #007ddf !important;
    background-color: #007ddf !important;
    border-radius: 5px;
    padding: 10px 20px;
    &:hover {
      background-color: #0470c2 !important;
    }
  }
}
// .common_scroll_input {
//   .rc-virtual-list-holder-inner {
//     max-height: 120px !important;
//     overflow: scroll !important;
//   }
// }
