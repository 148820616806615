.nc-PageHome {
  .desktop_box {
    width: 25rem;
  }
  .responsive_notification_box {
    .second_notification_box {
      @media (max-width: 1023px) and (min-width: 320px) {
        margin: 140px 0px 0px 0px !important;
      }
    }
  }

  .notification-from-top {
    top: 140px !important;
  }

  .right-img-container {
    position: absolute;
  }
  .right-image {
    width: 100%;
    min-height: 700px;
    padding-left: 130px;
    @media (max-width: 991px) and (min-width: 320px) {
      padding-left: 40px !important;
      min-height: 400px !important;
    }
  }
}

.nc-SectionHero {
  .form-section {
    .form-info {
      margin-top: 20px !important;
    }
    .focused_color {
      color: #fd4040;
    }
    form {
      margin-top: 30px !important;
    }
  }
  .list-container {
    margin: 15px 0px !important;
  }
}
.section2-container {
  background-image: url("../images/landingPage/backgroundDottedImage.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.section3-container {
  background-image: url("../images/landingPage/map_background-min3.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.the-impact-container {
  .list-container {
    .subtitle {
      margin: 0px !important;
    }
  }
}
.labelA-container {
  .label_content_image_container {
    width: 75%;
    @media (max-width: 1023px) and (min-width: 320px) {
      width: 100%;
    }
    .content_container {
      width: 75%;
      @media (max-width: 1023px) and (min-width: 320px) {
        width: 100%;
      }
    }
    .image_contianer {
      width: 25%;
      @media (max-width: 1023px) and (min-width: 320px) {
        width: 100%;
      }
    }
    .landing-grade-image-vertical-center {
      @media (max-width: 1023px) and (min-width: 320px) {
        padding: 20px 0px 0px 0px;
      }
    }
  }
  .img-container {
    .label-img {
      height: 360px !important;
    }
  }
}
.homepage-menuBox-container {
  margin: 0px !important;
  border-top: 1px solid #e0e1e5;
  border-bottom: 1px solid #e0e1e5;
  top: 90px;
  background: #fff;
  .logo-container {
    img {
      height: 30px;
    }
  }
  ul {
    margin: 0px;
    li {
      color: #fff;
      cursor: pointer;
      padding: 20px 25px;
      border-bottom: 1px solid #007ddf;
      // &:hover {
      //   color: #fff;
      //   border-bottom: 1px solid #fff;
      // }
    }
    .active-list {
      color: #4076f5;
    }
  }
}
.active-page {
  font-weight: bold;
  color: #fff !important;
  font-weight: 600;
  border-bottom: 3px solid #fff !important;
}

.home-page-benefits .img-content-container .hover-content h2,
.home-page-benefits .img-content-container .hover-content p {
  color: #fff;
}
.home-page-benefits .img-content-container .hover-content {
  display: none;
}
.home-page-benefits .img-content-container:hover .title-container {
  display: none;
}
.home-page-benefits .img-content-container:hover .hover-content {
  display: block;
  background-color: #54aaea;
}

.nc-SectionCity {
  .left-side-content {
    img {
      border-radius: 50%;
      height: 90%;
      width: 70%;
    }
  }
  .img-content-container {
    .img-container {
      width: 100%;
    }
  }
}
.carousal-container {
  .carousel.carousel-slider .control-arrow {
    color: red !important;
    font-size: 26px !important;
    margin: 300px 0px 0px 0px !important;
    padding: 5px !important;
    background: #fff !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    border: 1px solid gray !important;
  }
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
    display: block !important;
    // &:hover {
    //   background: #027ddf !important;
    // }
  }
  .carousel .thumbs-wrapper {
    display: none !important;
  }
  .carousel.carousel-slider {
    overflow: visible !important;
  }
  .carousel .control-next.control-arrow {
    right: auto !important;
    left: 46px !important;
    background: #007ddf !important;
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #455259 !important;
  }
}

.nc-faq-container {
  .collapse_list {
    ul {
      list-style: initial !important;
    }
    ol {
      list-style: block !important;
    }
  }
  .faq-container {
    .ant-collapse {
      border: none !important;
      background-color: #fff !important;
    }
    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      position: absolute !important;
      right: 0 !important;
    }
  }
}
.faq-banner-img {
  // background-image: url("../images/faqs/banner.png");
  background-image: url("../images/banners_header/banner_3.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
}
.contact-banner-img {
  @extend .faq-banner-img;
  background-image: url("../images/banners_header/banner_4.png");
}

.nc-whoWeArePage-banner-img {
  @extend .faq-banner-img;
  background-image: url("../images/Banner-new1.png");
}

.nc-process-banner-img {
  @extend .faq-banner-img;
  background-image: url("../images/Banner-new2.png");
}
.nc-SectionGridCategoryBox {
  .active-box-container {
    .active-box {
      position: absolute;
      width: 100%;
      background-color: #1990ff !important;
      height: 100%;
      border-radius: 5px;
      p {
        color: #fff;
      }
    }
  }
  .mobile_box {
    width: 33.333333%;
    padding: 0px 10px;
    @media (max-width: 1199px) and (min-width: 320px) {
      width: 100% !important;
      margin: 10px 0px !important;
    }
  }
}
.cardCateory6_container {
  .hover_box {
    // min-height: 260px;
    overflow: auto;
    padding: 0px 0px 20px 0px;
  }
}
.timeline_container {
  .home_benefits_timeline_container {
    .box_text_container {
      position: absolute;
      padding: 8px 30px;
      p {
        margin: 0px;
        font-size: 13px;
      }
    }
    .box_container {
      width: 60%;
      .box1 {
        background: url("../images/landingPage/timeline/CombinedShape.svg");
        background-repeat: no-repeat;
        height: 50px;
      }
    }
  }
  .home_benefits_timeline_container {
    .box_container {
      // width: 20%;
      .box2 {
        background: url("../images/landingPage/timeline/CombinedShape-1.svg");
        background-size: contain;
        background-repeat: no-repeat;
        // width: 100%;
        height: 42px;
      }
    }
  }
  .home_benefits_timeline_container {
    .box_container {
      // width: 20%;
      .box3 {
        background: url("../images/landingPage/timeline/CombinedShape-2.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: 42px;
      }
    }
  }
}
.timeline_container {
  @media (max-width: 768px) and (min-width: 320px) {
    flex-direction: column;
    .image_timeLine1 {
      width: 100% !important;
      clip-path: polygon(
        96% 0,
        100% 50%,
        96% 100%,
        0% 100%,
        5% 47%,
        0% 0%
      ) !important;
    }
    .image_timeLine2 {
      width: 100% !important;
      margin: 10px 0px 0px 0px;
      padding: 12px 20px;
    }
    .image_timeLine3 {
      width: 100% !important;
      padding: 12px 20px;
    }
  }
}
.image_timeLine1 {
  // background: url("../images/timeline/timeline_1.svg");
  clip-path: polygon(98.5% 0, 100% 50%, 98.5% 100%, 0% 100%, 2% 48%, 0% 0%);
  background: #e3e7eb;
  background-repeat: no-repeat;
  width: 60vw;
  padding: 12px 20px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_timeLine2 {
  // background: url("../images/timeline/timeline_2.svg");
  clip-path: polygon(96% 0, 100% 50%, 96% 100%, 0% 100%, 5% 47%, 0% 0%);
  background: #e3e7eb;
  background-repeat: no-repeat;
  width: 18vw;
  font-size: 13px;
  padding: 0px 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.process-page-snake-image {
  background: url("../images/process/Shape.svg") no-repeat 45% center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0px 20px;
  height: 90%;
  @include breakpoint(tablet) {
    background-image: none !important;
  }
}

.benefit-page-snake-image {
  background: url("../images/process/Shape.svg");
  background-size: 100% 86%;
  background-repeat: no-repeat;
  background-position: 0px 100px;
  z-index: -1 !important;

  @include breakpoint(tablet) {
    background-image: none !important;
  }
}

.nc-Cookies {
  width: 25%;
  background-color: #fff;
  margin: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  p {
    font-size: 15px !important;
    text-align: justify;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 90%;
  }
}

.full-width-responsive {
  @include breakpoint(tablet) {
    width: 100% !important;
    display: initial !important;
  }
}
.homePage_sectionCity_container {
  .sectionCity_content_container {
    .left_side {
      width: 33%;
      @media (max-width: 1023px) and (min-width: 320px) {
        width: 100%;
        .image_container {
          display: flex;
          justify-content: center;
        }
      }
    }
    .right_side {
      width: 67%;
      @media (max-width: 1023px) and (min-width: 320px) {
        width: 100%;
        padding: 20px 0px !important;
      }
    }
  }
}
